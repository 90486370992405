import { paymentOrderListAll } from '@/api/financial/detailbtn'
export const tableCols1 = [
  {
    prop: 'paymentOrderCode',
    label: '支付订单号'
  },
  {
    prop: 'nickName',
    label: '分销商名称',
    slotName: 'nickNameSlot'
  },
  {
    prop: 'orderCount',
    label: '订单数'
  },
  {
    prop: 'remark',
    label: '备注'
  },
  {
    prop: 'totalAmount',
    label: '应付总额'
  },
  {
    prop: 'realAmount',
    label: '实际应付总额'
  },
  {
    prop: 'createTime',
    label: '提交时间'
  },
  {
    prop: 'operation',
    label: '操作',
    slotName: 'operationSlot'
  }
]
export const tableCols2 = [
  {
    prop: 'paymentOrderCode',
    label: '支付订单号'
  },
  {
    prop: 'orderCount',
    label: '订单数'
  },
  {
    prop: 'nickName',
    label: '分销商名称',
    slotName: 'nickNameSlot'
  },
  {
    prop: 'totalAmount',
    label: '应付总金额'
  },
  {
    prop: 'realAmount',
    label: '实付总金额'
  },
  {
    prop: 'paymentType',
    label: '付款方式',
    slotName: 'paymentTypeSlot'
  },
  {
    prop: 'remark',
    label: '备注'
  },
  {
    prop: 'paymentTime',
    label: '付款时间'
  },
  {
    prop: 'approveTime',
    label: '审核时间'
  },
  {
    prop: 'operation',
    label: '操作',
    slotName: 'operationSlot'
  }
]

export const list = [
  {
    label: '未支付',
    value: 0,
    getList: paymentOrderListAll,
    resetMergeData: {
      paymentStatus: 0
    },
    option: {
      searchLabelWidth: 88,
      selection: true,
      column: [
        {
          prop: 'paymentOrderCode',
          label: '支付订单号',
          search: true
        },
        {
          prop: 'nickName',
          label: '分销商名称',
          slotName: 'nickNameSlot',
          search: true
        },
        {
          prop: 'orderCount',
          label: '订单数'
        },
        {
          prop: 'remark',
          label: '备注'
        },
        {
          prop: 'totalAmount',
          label: '应付总额'
        },
        {
          prop: 'realAmount',
          label: '实际应付总额'
        },
        {
          prop: 'createTime',
          label: '提交时间',
          searchFormSlot: true,
          search: true,
          searchWidth: 555,
          searchLabelWidth: 100,
          type: 'daterange',
          valueFormat: 'yyyy-MM-dd'
        }
      ]
    }
  },
  {
    label: '已支付',
    value: 1,
    getList: paymentOrderListAll,
    resetMergeData: {
      paymentStatus: 1
    },
    option: {
      searchLabelWidth: 88,
      align: 'center',
      menuFixed: 'normal',
      menuAlign: 'center',
      editBtn: false,
      delBtn: false,
      selection: true,
      column: [
        {
          prop: 'paymentOrderCode',
          label: '支付订单号',
          search: true
        },
        {
          prop: 'orderCodes',
          label: '订单号',
          search: true,
          searchFormSlot: true,
          hide: true
        },
        {
          prop: 'orderCount',
          label: '订单数'
        },
        {
          prop: 'nickName',
          label: '分销商名称',
          slotName: 'nickNameSlot',
          search: true
        },
        {
          prop: 'totalAmount',
          label: '应付总金额'
        },
        {
          prop: 'realAmount',
          label: '实付总金额'
        },
        {
          prop: 'paymentType',
          label: '付款方式',
          search: true,
          type: 'select',
          dicData: [
            { label: '支付宝', value: 1 },
            { label: '微信', value: 2 },
            { label: '银行卡', value: 3 },
            { label: '余额付款', value: 4 },
            { label: '免支付', value: 5 },
            { label: '信用额度', value: 6 }
          ],
          slotName: 'paymentTypeSlot'
        },
        {
          prop: 'remark',
          label: '备注'
        },
        {
          prop: 'paymentTime',
          label: '付款时间',
          search: true,
          searchFormSlot: true,
          searchWidth: 555,
          searchLabelWidth: 100,
          type: 'daterange',
          valueFormat: 'yyyy-MM-dd'
        },
        {
          prop: 'approveTime',
          label: '审核时间'
        }
      ]
    }
  }
]
