import request from '@/service/request'

// 列表
export function paymentOrderList(data) {
  return request({
    url: '/externaladmin/orderService/order/paymentOrderList',
    method: 'post',
    data
  })
}

// 详情
export function paymentOrderItem(data) {
  return request({
    url: '/externaladmin/orderService/orderItem/paymentOrderItem',
    method: 'post',
    data
  })
}
// 账单导出
export function reckoningExport(data) {
  return request({
    url: '/externaladmin/financeService/reckoning/export',
    method: 'post',
    responseType: 'blob',
    data
  })
}

// 账单导出（旧）
export function reckoningExportOld(data) {
  return request({
    url: '/externaladmin/orderService/reckoning/export',
    method: 'post',
    responseType: 'blob',
    data
  })
}

export function paymentOrderListAll(data) {
  return request({
    url: '/externaladmin/financeService/paymentOrder/paymentOrderList',
    method: 'post',
    data
  })
}
